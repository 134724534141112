import React from 'react'
import Style from './Product.module.css'
import { Link } from 'react-router-dom'

const Product = () => {
    return (
        <div className={Style.Product__Section}>
            <div className='container'>
                <div className='Section__Title'>
                    <h3 className='Title mb-0'>Have a trusted advisor by your side</h3>
                </div>
                <div className='row g-3 g-lg-4 g-xxl-5 row-cols-1 row-cols-lg-3'>
                    <div className='col'>
                        <div className={Style.Product__Item}>
                            <h5 className={Style.Title}>Bookkeeping support</h5>
                            <div className={Style.Desc}>We'll run the numbers. You run your business.</div>
                            <div className='row row-cols-1 g-2 mb-4 pb-3'>
                                <div className='col'>
                                    <div className='Check__Text'>Transaction categorization</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Account reconciliation</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Collaboration with your accountant</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Monthly review calls</div>
                                </div>
                            </div>
                            <a href="#Pricing__Section" className={`Blue__Button Outline ${Style.Button}`}>See Pricing</a>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Product__Item}>
                            <h5 className={Style.Title}>Accounting & payroll coaching</h5>
                            <div className={Style.Desc}>With this personalized session, you'll get...</div>
                            <div className='row row-cols-1 g-2 mb-4 pb-3'>
                                <div className='col'>
                                    <div className='Check__Text'>2 hours of live coaching</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Account reconciliation</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Unlimited 30-day email access to your coach</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>The confidence to manage your own books</div>
                                </div>
                            </div>
                            <a href="#Pricing__Section" className={`Blue__Button Outline ${Style.Button}`}>See Pricing</a>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Product__Item}>
                            <h5 className={Style.Title}>Tax Coaching</h5>
                            <div className={Style.Desc}>Relax, we'll take care of your questions.</div>
                            <div className='row row-cols-1 g-2 mb-4 pb-3'>
                                <div className='col'>
                                    <div className='Check__Text'><strong>Specialized</strong> for small business <strong>income</strong> tax issues and planning</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>One <strong>video call</strong> up to 60 minutes</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Unlimited 30 day <strong>email access</strong> to your tax pro</div>
                                </div>
                                <div className='col'>
                                    <div className='Check__Text'>Monthly review calls</div>
                                </div>
                            </div>
                            <Link to={'https://calendly.com/payyit'} target='_blank' className={`Blue__Button Outline ${Style.Button}`}>Book a call</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product
