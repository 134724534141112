import React from 'react';
import Plans from './Section/Plans';
import Faq from './Section/Faq';
import Fees from './Section/Fees';
import CallToAction from './Section/CallToAction';

function Pricing() {
  return (
    <div>
        <div className='Navbar__Height'></div>
        <Fees />
        <Plans />
        <Faq />
        <CallToAction />
    </div>
  );
}

export default Pricing;
