import React from 'react'
import styles from './Header.module.css'; // Import CSS modules
import Header__Image from './Images/invoicing-hero.png';
import { Link } from 'react-router-dom';
const Header = () => {
    return (
        <div className={styles.Header__Section}>
            <div className='container'>
                <div className='row g-5 align-items-center'>
                    <div className='col-lg-6 text-center text-lg-start'>
                        <h1 className={styles.Main__Title}>Invoicing software that makes you look good</h1>
                        <div className={styles.Description}>
                            <p>Payyit's invoicing software saves you time, gets you paid, and makes managing customer information stress-free.</p>
                        </div>
                        <Link to={'https://dashboard.payyit.com/signup'} target='_blank' className='Lilac__Button'>Create an Account</Link>
                        <div className='Text__Gray mt-3'>
                            <p>By signing up, you are indicating that you have read and agree to the <Link to={'/terms'}>Terms of Use</Link> and <Link to={'/privacy'}>Privacy Policy</Link>.</p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <figure className={styles.Header__Image}>
                            <img src={Header__Image} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
