import React from 'react';
import styles from './CallToAction.module.css';

// Images Import
import ActionImage from './Images/CallToAction.webp';
import { Link } from 'react-router-dom';
const CallToAction = () => {
    return (
        <div className={styles.CallToAction__Section}>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10'>
                        <div className={styles.Card}>
                            <div className='row gx-3 gy-4 g-lg-5 align-items-center'>
                                <div className='col-lg-5'>
                                    <figure className='m-0 text-center'>
                                        <img src={ActionImage} alt='' width={'250px'} />
                                    </figure>
                                </div>
                                <div className='col-lg-7 text-center text-lg-start'>
                                    <h3 className={styles.Title}> Do what you love. Leave the rest to us. </h3>
                                    <Link to={'https://dashboard.payyit.com/signup'} className='Primary__Button'>Let's do this</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallToAction
