import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import ReactRouter from './reactRouter';
import Footer from './components/Footer';



function App() {
  return (
    <BrowserRouter>
        <Navbar />
        <ReactRouter />
        <Footer />
    </BrowserRouter>
  );
}

export default App;