import React from 'react';
import Style from './Loader.module.css';
import Logo from './Images/favicon.png'

const Loader = () => {
  return (
    <div className={Style.Loader__Wrapper}>
      <div className={Style.Loader__Icon}>
        <img src={Logo} alt='' />
      </div>
    </div>
  )
}

export default Loader
