import React from 'react'
import Style from './Pricing.module.css'
import { Link } from 'react-router-dom'

const Pricing = () => {
    return (
        <div className={Style.Pricing__Section} id='Pricing__Section'>
            <div className='container medium'>
                <div className='row g-3 g-lg-4'>
                    <div className='col'>
                        <div className={Style.Pricing__Item}>
                            <h3 className={Style.Name}>Bookkeeping support</h3>
                            <div>
                                <h4 className={Style.Amount}>$149</h4>
                                <div className={Style.Period}>USD and up per month*</div>
                            </div>
                            <Link className='Lilac__Button' target='_blank' to={'https://calendly.com/payyit'}>Book a call</Link>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Pricing__Item}>
                            <h3 className={Style.Name}>Accounting & payroll coaching</h3>
                            <div>
                                <h4 className={Style.Amount}>$379</h4>
                                <div className={Style.Period}>USD one time fee <br />
                                    (other packages available)</div>
                            </div>
                            <Link className='Lilac__Button' target='_blank' to={'https://calendly.com/payyit'}>Book a call</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <small>*For prepaid annual plans. No commitment necessary. Month-to-month plans also available.</small>
                </div>
            </div>
        </div>
    )
}

export default Pricing
