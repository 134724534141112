import React from 'react';
import Header from './Sections/Header';
import Service from './Sections/Service';
import Customer from './Sections/Customer';
import Faq from './Sections/Faq';
import CallToAction from './Sections/CallToAction';
import About from './Sections/About';
import Feature from './Sections/Feature';

const Home = () => {
  return (
    <div className='Home__Page__Content'>
      <Header />
      <About />
      <Service />
      <Feature />
      <Customer />
      <Faq />
      <CallToAction />
    </div>
  )
}

export default Home
