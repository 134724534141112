import React from 'react';
import styles from './Feature.module.css'; // Import CSS modules

import Feature1 from './Images/Feature1.webp';
import Feature2 from './Images/Feature2.webp';
import Feature3 from './Images/Feature3.webp';


const Feature = () => {
    return (
        <div className={styles.Feature__Section}>
            <div className='container medium'>
                <div className={styles.Section__Feature__Content}>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' >
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Feature1} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Enjoy extra security powered by Visa®</h3>
                            <div className='mb-4'>
                                <p>Temporarily lock your card if you misplace it and unlock it when it's found. Shop securely with the protection of Visa Zero Liability and built-in chip technology.</p>
                            </div>    
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' >
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Feature2} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Managing your Payyit Blue Visa Debit</h3>
                            <div className='mb-4'>
                                <p>Easily monitor your Payyit Blue Visa Debit transactions and transfer money to and from your balance.</p>
                            </div>    
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' >
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Feature3} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>How to add money to your Payyit balance</h3>
                            <div className='mb-4'>
                                <p>The Payyit Blue Visa Debit is linked to your balance so it's easy to add money whenever you like.</p>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
