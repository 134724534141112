import React from 'react'
import Style from './PaymentFeature.module.css'

const PaymentFeature = () => {
    return (
        <div className={Style.PaymentFeature__Section}>
            <div className='container medium'>
                <h5 className='fw-bold mb-5'>See all payments features</h5>
                <div className='row row-cols-1 row-cols-md-2 g-2'>
                    <div className='col'>
                        <div className='Check__Text'>Automatic receipts for processed payments</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Add your logo and brand colours</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Allow saved payment methods</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Drag-and-drop editing</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Choose preferred payment method</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Create once and duplicate</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Flexible scheduling</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Change sender or add recipients</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Time zone control</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Add custom messages</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Turn recurring on and off</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Filter by invoice status for easier viewing</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Turn off recurring without deleting your invoice</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Hover over an invoice to view details on the main page</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Automatically send invoices to multiple recipients</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>See all invoices created on a recurring schedule</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentFeature
