import React, { useState } from 'react';
import Style from './RegisterForm.module.css';

const RegisterForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleCreateAccount = () => {
        // Construct the URL with email and password as query parameters
        const url = `https://dashboard.payyit.com/signup?useremail=${encodeURIComponent(email)}&userpass=${encodeURIComponent(password)}`;
        
        // Open the URL in a new tab
        window.open(url, '_blank');
    };

    return (
        <div className={Style.Register__Form}>
            <div className='row g-3 row-cols-1'>
                <div className='col'>
                    <input 
                        type='email' 
                        className='Form__Control' 
                        placeholder='Enter your email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='col'>
                    <input 
                        type='password' 
                        className='Form__Control' 
                        placeholder='Create a password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className='col'>
                    <div className={Style.Form__Actions}>
                        <button className='Lilac__Button' onClick={handleCreateAccount}>Create your account</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterForm;
