import React from 'react'
import Header from './Section/Header';
import Service from './Section/Service';
import Feature from './Section/Feature';


const Bluecard = () => {
  return (
    <div className='Bluecard__Page__Wrapper'>
      <Header />
      <Service />
      <Feature />
    </div>
  )
}

export default Bluecard
