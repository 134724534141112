import React from 'react'
import Style from './Payment.module.css'
import Dollar__Icon from './Images/payments__dollar__icon.webp'
import Dollar__Hand from './Images/dollar__hand.webp'
import Money__Down from './Images/Money__Down.webp'

const Payment = () => {
  return (
    <div className={Style.Payment__Section}>
      <div className='container'>
        <div className='Section__Title'>
          <h3 className='Title text-start text-center m-0'>How accepting cards and digital payments works</h3>
        </div>
        <div className='row g-3 gy-4 g-lg-4 g-xxl-5 row-cols-1 row-cols-lg-3'>
          <div className='col'>
            <div className={Style.Payment__Item}>
              <div className={Style.Icon}>
                <img src={Dollar__Icon} alt='' />
              </div>
              <h5 className={Style.Title}>1. Send your invoices</h5>
              <div className={Style.Description}>
                <p>Email invoices with a secure “Pay Now” button after you've enabled the online payments option.</p>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className={Style.Payment__Item}>
              <div className={Style.Icon}>
                <img src={Dollar__Hand} alt='' />
              </div>
              <h5 className={Style.Title}>2. Get paid online</h5>
              <div className={Style.Description}>
                <p>Customers can pay instantly by credit card or Apple Pay when they view the invoice online.</p>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className={Style.Payment__Item}>
              <div className={Style.Icon}>
                <img src={Money__Down} alt='' />
              </div>
              <h5 className={Style.Title}>3. Money is deposited</h5>
              <div className={Style.Description}>
                <p>You'll receive the money in your account in 2 business days.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
