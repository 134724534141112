import React from 'react'
import Style from './Feature.module.css'
import Image_1 from './Images/Image_1.webp';
import Image_2 from './Images/Image_2.png';
import Image_3 from './Images/Image_3.webp';

const Feature = () => {
    return (
        <div>
            <div className={Style.Feature__Section}>
                <div className='container medium'>
                    <div className='row justify-content-center'>
                        <div className='col-xl-10'>
                            <div className='Section__Title'>
                                <h3 className='Title text-start text-md-center'>Accounting software that works as hard as you do</h3>
                                <ul className='Inline__Check__List Description'>
                                    <li>Simple, reliable, and secure</li>
                                    <li>Easily manage cash flow</li>
                                    <li>Be ready for tax time</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 g-lg-5 align-items-center'>
                        <div className='col-lg-6'>
                            <h5 className='fw-bold'>Keep track of your business health</h5>
                            <div className='Description'>
                                <p>Have an eye on the big picture so you can make better business decisions. Our robust small business accounting reports are easy to use and show month-to-month or year-to-year comparisons so you can easily identify cash flow trends.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0 text-lg-end'>
                                <img src={Image_1} alt='' />
                            </figure>
                        </div>
                    </div>
                    <div className='row g-3 g-lg-5 align-items-center mt-0 flex-row-reverse'>
                        <div className='col-lg-6'>
                            <h5 className='fw-bold'>Unlimited bank and credit card connections</h5>
                            <div className='Description'>
                                <p>Connect your bank accounts in seconds with the Pro Plan. Transactions will appear in your bookkeeping automatically, and you'll say goodbye to manual receipt entry.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0 text-lg-end'>
                                <img src={Image_2} alt='' />
                            </figure>
                        </div>
                    </div>
                    <div className='row g-3 g-lg-5 align-items-center mt-0'>
                        <div className='col-lg-6'>
                            <h5 className='fw-bold'>Get more time for what you love</h5>
                            <div className='Description'>
                                <p>With the Pro Plan, automatically import, merge, and categorize your bank transactions. Access your data anywhere, any time. It's always available, and it's backed up for extra peace of mind.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0 text-lg-end'>
                                <img src={Image_3} alt='' />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
