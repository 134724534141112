import React from 'react'
import Header from './Section/Header'
import Feature from './Section/Feature'
import Testimonial from './Section/Testimonial'
import Payment from './Section/Payment'
import Promo from './Section/Promo'
import Faq from './Section/Faq'
import CallToAction from './Section/CallToAction'

const Payments = () => {
  return (
    <div className='Payments__Page__Content'>
      <Header />
      <Feature />
      <Testimonial />
      <Payment />
      <Promo />
      <Faq />
      <CallToAction />
    </div>
  )
}

export default Payments
