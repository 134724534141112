import React from 'react'
import Header from './Sections/Header'
import Service from './Sections/Service'
import Feature from './Sections/Feature'
import Testimonial from './Sections/Testimonial'
import Promo from './Sections/Promo'
import PaymentFeature from './Sections/PaymentFeature'
import Faq from './Sections/Faq'
import CallToAction from './Sections/CallToAction'

const Invoicing = () => {
  return (
    <div>
      <Header />
      <Service />
      <Feature />
      <Testimonial />
      <Promo />
      <PaymentFeature />
      <Faq />
      <CallToAction />
    </div>
  )
}

export default Invoicing
