import React from 'react'
import Header from './Section/Header'
import Feature from './Section/Feature'
import Promo from './Section/Promo'
import Testimonial from './Section/Testimonial'

import CallToAction from './Section/CallToAction'
import Faq from './Section/Faq'
import PaymentFeature from './Section/PaymentFeature'

const Recurring = () => {
  return (
    <div className='Recurring__Content__Page'>
      <Header />
      <Feature />
      <Promo />
      <Testimonial />
      <PaymentFeature />
      <Faq />
      <CallToAction />
    </div>
  )
}

export default Recurring
