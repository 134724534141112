import React from 'react';
import styles from './Feature.module.css'; // Import CSS modules

import Feature1 from './Images/Feature1.webp';
import Feature2 from './Images/Feature2.webp';
import Feature3 from './Images/Feature3.webp';


const Feature = () => {
    return (
        <div className={styles.Feature__Section}>
            <div className='container medium'>
                <div className='row justify-content-center'>
                    <div className='col-xl-10'>
                        <div className='Section__Title m-0'>
                            <h2 className='Title m-0'>How Payyit.Me Lynk Works</h2>
                        </div>
                    </div>
                </div>
                <div className={styles.Section__Feature__Content}>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' >
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Feature1} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Choose your own link</h3>
                            <div className='mb-4'>
                                <p>Create your own Payyit.Me Lynk and share it instantly with anyone: friends, customers, or partners. If you don't already have a Payyit.Me Lynk, signing up is fast and free. And you can start accepting money from anyone in an instant.</p>
                            </div>    
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' >
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Feature2} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Share your link easily</h3>
                            <div className='mb-4'>
                                <p>Share your Payyit.Me Lynk the way you want to. In an email, a text message, on a chat, or even on your business card.</p>
                            </div>    
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' >
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Feature3} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Get paid, real fast.</h3>
                            <div className='mb-4'>
                                <p>Friends, family, or customers can follow the link, enter any amount and that's it</p>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
