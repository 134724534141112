import React, {useEffect, useState} from 'react';
import Style from './Fees.module.css';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import Select from 'react-select';
import Flags from 'react-country-flag'; // Import react-country-flag

const Fees = () => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [country, setCountry] = useState(null);

    useEffect(() => {
        // Fetch country data from your API
        fetch(`${process.env.REACT_APP_API_URL}/utility/public/all-country-price`)
            .then(response => response.json())
            .then(data => {
                // Assuming your API response contains an array of country objects
                setCountries(data.data.countryPrice);
            })
            .catch(error => {
                console.error('Error fetching country data:', error);
            });
    }, []);

    useEffect(() => {
        // Set the default selected country as the first one when countries data is available
        if (countries.length > 0 && country) {
            const findCountry = options.find(({countryData}) => {
               return countryData.sortname === country
            });
            console.log({findCountry})
            setSelectedCountry(findCountry || options[0]);
        }
        // eslint-disable-next-line
    }, [countries, country]);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await fetch('https://api.country.is/');
                const data = await response.json();
                console.log({data})
                setCountry(data.country);
            } catch (error) {
                console.error('Error fetching country:', error);
            }
        };
        fetchCountry();
    }, []);

    const options = countries.map(country => ({
        value: country.name,
        label: (
            <span className='d-flex align-items-center'>
                <Flags className='me-2' countryCode={country.alpha2Code} svg /> {/* Display flag */}
                <span>{country.name}</span>
            </span>
        ),
        countryData: country // Include the country data along with the option
    }));

    const handleChange = selectedOption => {
        setSelectedCountry(selectedOption);
    };

    return (
        <div className={Style.Fees__Section}>
            <div className='container medium'>
                <div className='Section__Title'>
                    <h3 className='Title text-start text-xxl-center m-0'>The right plan for your business</h3>
                </div>
                <div className='row justify-content-center mb-4 g-0'>
                    <div className='col-md-4 col-sm-6 px-md-4'>
                        <Select
                            options={options}
                            value={selectedCountry}
                            onChange={handleChange}
                            placeholder="Select a country"
                        />
                    </div>
                </div>
                <div className={Style.Fees__Card}>
                    <div className='p-4 p-md-5'>
                        <div className='row g-3 g-md-4 g-lg-5'>
                            <div className='col-12 text-center'>
                                <h4 className='fw-bold m-0'>Domestic & International <br /> <small>Pass-fee enabled</small> </h4>
                            </div>
                            <div className='col-sm-12 text-center'>
                                <div className='mb-0'>
                                    <h4 className='fw-light'><strong>0%</strong></h4>
                                    <div className='Description'>Credit &amp; Debit Cards with<br />Chargeback Insurance <Tooltip title={`The user is protected from the unpredictability of disputes. If your business faces a fraudulent dispute, we will cover the disputed amount and waive any dispute fees — submission of evidence may be required. This added layer of security only applies to credit/debit card payments processed through Payyit. This feature is not available for payments using Discover card.`} position="top" trigger="mouseenter" arrow={true} ><i className='fa-regular fa-circle-info'></i></Tooltip></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Style.Horizontal__Line}><span>OR</span></div>
                    <div className='p-4 p-md-5'>
                        <div className='row g-3 g-sm-4 g-lg-5'>
                            <div className='col-md-5'>
                                <h4 className={`${Style.Badge__Title} ${Style.Pro}`}><span className={Style.Badge}>Domestic</span> <small>Pass-fee disabled</small></h4>
                                <div className='mb-4'>
                                    <h6 className='fw-bold m-0'>
                                        {selectedCountry && selectedCountry.countryData.public.domestic.length === 1
                                            ? selectedCountry.countryData.public.domestic[0].fee
                                            : selectedCountry && selectedCountry.countryData.public.domestic.length > 1
                                                ? selectedCountry.countryData.public.domestic[1].fee
                                                : 'Fee not found'}
                                    </h6>
                                    <div className='Description'>Credit &amp; Debit Cards with<br />Chargeback Insurance <Tooltip title={`The user is protected from the unpredictability of disputes. If your business faces a fraudulent dispute, we will cover the disputed amount and waive any dispute fees — submission of evidence may be required. This added layer of security only applies to credit/debit card payments processed through Payyit. This feature is not available for payments using Discover card.`} position="top" trigger="mouseenter" arrow={true} ><i className='fa-regular fa-circle-info'></i></Tooltip></div>
                                </div>
                            </div>
                            <div className='col-md-2 text-center'>
                                <div className={Style.Verticle__Line} />
                            </div>
                            <div className='col-md-5'>
                                <h4 className={`${Style.Badge__Title} ${Style.Pro}`}><span className={Style.Badge}>International</span> <small>Pass-fee disabled</small></h4>
                                <div className='mb-4'>
                                    <h6 className='fw-bold m-0'>
                                        {selectedCountry && selectedCountry.countryData.public.international.length === 1
                                            ? selectedCountry.countryData.public.international[0].fee
                                            : selectedCountry && selectedCountry.countryData.public.international.length > 1
                                                ? selectedCountry.countryData.public.international[1].fee
                                                : 'Fee not found'}
                                    </h6>
                                    <div className='Description'>Credit &amp; Debit Cards with<br />Chargeback Insurance <Tooltip title={`The user is protected from the unpredictability of disputes. If your business faces a fraudulent dispute, we will cover the disputed amount and waive any dispute fees — submission of evidence may be required. This added layer of security only applies to credit/debit card payments processed through Payyit. This feature is not available for payments using Discover card.`} position="top" trigger="mouseenter" arrow={true} ><i className='fa-regular fa-circle-info'></i></Tooltip></div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fees;
