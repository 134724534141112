import React, { useState } from 'react';
import styles from './Feature.module.css';
import Feature1Image from './Images/Feature-1.webp';
import Feature2Image from './Images/Feature-2.png';
import Feature3Image from './Images/Feature-3.webp';

const Feature = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const Feature = [
    {
      title: 'Look professional with customizable invoices',
      description: 'Create beautiful invoices that reflect your business branding. Automate overdue reminders, set up recurring bills, and add notes or terms of service with ease.',
      image: Feature1Image,
    },
    {
      title: 'Make tax time a breeze',
      description: 'When everything is neatly where it belongs, tax time is simple. Payyit smart dashboard organizes your income, expenses, payments, and invoices.',
      image: Feature2Image,
    },
    {
      title: 'Enjoy customers paying you 3x faster when you enable online payments',
      description: 'Allow your customers to pay your invoices immediately via credit card, secure bank payment (ACH/EFT), or Apple Pay. You’ll both appreciate the speed and convenience.',
      image: Feature3Image,
    },
  ];

  return (
    <div className={styles.Feature__Section}>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-10'>
            <div className='Section__Title'>
              <h3 className='Title'>Pocket- and small business-friendly perks</h3>
              <div className='Description'>
                <p>Ready to invoice in style, bookkeep less, and get paid fast? You’re in the right place.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row gx-3 gy-5 g-lg-5 justify-content-center align-items-center'>
          <div className='col-lg-5'>
            <figure className={styles.Tab__Image}>
              <img src={Feature[activeTab].image} alt={Feature[activeTab].title} />
            </figure>
          </div>
          <div className='col-lg-6 offset-lg-1'>
            <div className={styles.Tab__List}>
              {Feature.map((tab, index) => (
                <div
                  key={index}
                  className={`${styles.Tab__Item} ${index === activeTab ? styles.Active : ''}`}
                  onClick={() => handleTabClick(index)}
                >
                  <h5 className={styles.Title}>{tab.title}</h5>
                  <div className='Description'>
                    <p>{tab.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
