import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Style from './Plans.module.css';
import Arrow from './Images/discount_arrow.svg';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'; // Import Tippy's CSS
import Loader from '../../../../components/Loader';

const Plans = () => {
    const [selectedOption, setSelectedOption] = useState('monthly');
    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/plans?recurring=${selectedOption === 'annual' ? 'yearly' : 'monthly'}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setPlans(data.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, [selectedOption]);

    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className={Style.Plans__Section}>
            <div className='container-fluid'>
                <div className='Section__Title'>
                    <h3 className='Title text-start text-center mb-5'>How accepting cards and digital payments works</h3>
                </div>
                <div className='text-center'>
                    <div className={`${Style.Price__Tabs}`}>
                        <button
                            type='button'
                            className={`${Style.Price__Tab} ${selectedOption === 'monthly' ? Style.Active__Monthly : ''}`}
                            onClick={() => handleOptionClick('monthly')}
                        >Monthly</button>
                        <button
                            type='button'
                            className={`${Style.Price__Tab} ${selectedOption === 'annual' ? Style.Active__Annual : ''}`}
                            onClick={() => handleOptionClick('annual')}
                        >Annual<span className={Style.Discount__Badge}><img src={Arrow} alt='' /> Save Two Months</span>
                        </button>
                    </div>
                </div>
                <div className='mb-5 d-block' />
                {loading ? (
                    <Loader />
                ) : (
                    <div className='row gx-3 gy-5 g-md-4 justify-content-center'>
                        {plans.map((plan, index1) => (
                            <div key={index1} className='col-md-6 col-xl-5 col-xxl-3'>
                                <div className={`${Style.Price__Table} ${plan.isRecomended ? Style.Pro : ''}`}>
                                    <div className={Style.Price__Header}>
                                        {plan.isRecomended && <div className={Style.Recomand__Badge}>Recommended</div>}
                                        <div className={Style.Plan__Name}>
                                            <span className={Style.Badge}>{plan.title}</span> Plan
                                        </div>
                                        <div className={Style.Short__Desc}>
                                            <strong>{plan.rewardInfo.title}</strong> {plan.rewardInfo.info}
                                        </div>
                                        <h3 className={Style.Price__Amount}>
                                            {plan.price !== 0 ? (
                                                <>
                                                    <span className={Style.Amount}>
                                                    {plan.sellingPrice !== 0 && <del>${plan.sellingPrice}</del>} {plan.sellingPrice !== 0 && <span> - </span>} ${plan.price}
                                                    </span>
                                                    <span className={Style.Period}>/{plan.recurring === 'yearly' ? "Year" : "Month"}</span>
                                                    <span className={Style.Label}>Billed {plan.recurring}</span>
                                                </>
                                            ) : (
                                                <span className={Style.Amount}>
                                                    ${plan.price}
                                                </span>
                                            )}
                                        </h3>
                                        <Link to={`https://dashboard.payyit.com/signup?planType=${plan.planLevel}`} className="Blue__Button w-100">Get started now</Link>
                                    </div>
                                    <div className={Style.Price__Content}>
                                        <div className='row g-2 g-lg-3 row-cols-1'>
                                            {plan.features.map((feature, index) => (
                                                <div key={index} className='col'>
                                                    <div className='Check__Text'>{feature.title} <Tooltip title={feature.info ? feature.info : ''} position="top" trigger="mouseenter" arrow={true} >{feature.info && <i className='fa-regular fa-circle-info'></i>}</Tooltip></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Plans;
