import React from 'react'
import Style from './Testimonial.module.css'

import Image1 from './Images/Testimonial__1.png';
import Image2 from './Images/Testimonial__2.png';
import Image3 from './Images/Testimonial__3.png';

const Testimonial = () => {
    return (
        <div className={Style.Testimonial__Section}>
            <div className='container'>
                <div className='row row-cols-1 row-cols-lg-3 g-3 g-lg-4'>
                    <div className='col'>
                        <div className={`${Style.Testimonial__Item} ${Style.Item1}`}>
                            <div className={Style.Header}>
                                <div className={Style.Photo}>
                                    <img src={Image1} alt='' />
                                </div>
                                <div className={Style.Info}>
                                    <h4 className={Style.Name}>Jake Smith</h4>
                                    <div className={Style.Position}>Sales Associate</div>
                                </div>
                            </div>
                            <div className={Style.Content}>
                                <p>Jason helps me a lot. He goes extra to help because I am <strong>new to freelancing</strong> and using Payyit Advisor services.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={`${Style.Testimonial__Item} ${Style.Item2}`}>
                            <div className={Style.Header}>
                                <div className={Style.Photo}>
                                    <img src={Image2} alt='' />
                                </div>
                                <div className={Style.Info}>
                                    <h4 className={Style.Name}>Tom Johnson</h4>
                                    <div className={Style.Position}>Content Writer</div>
                                </div>
                            </div>
                            <div className={Style.Content}>
                                <p>Dawn is always upbeat and <strong>helpful, even when not feeling great</strong>. She's organized and available for my questions.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={`${Style.Testimonial__Item} ${Style.Item3}`}>
                            <div className={Style.Header}>
                                <div className={Style.Photo}>
                                    <img src={Image3} alt='' />
                                </div>
                                <div className={Style.Info}>
                                    <h4 className={Style.Name}>Emily Clark</h4>
                                    <div className={Style.Position}>Marketing Coordinator</div>
                                </div>
                            </div>
                            <div className={Style.Content}>
                                <p>It's great that you find it easy! <strong>I'm here to help, so you</strong> don't have to worry about balancing everything yourself.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
