import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Invoicing from './pages/Invoicing';
import Payments from './pages/Payments';
import Accounting from './pages/Accounting';
import Advisor from './pages/Advisor';
import Recurring from './pages/Recurring';
import Pricing from './pages/Pricing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Security from './pages/Security';
import Protection from './pages/Protection';
import Payyitme from './pages/Payyitme';
import Bluecard from './pages/Bluecard';

function ReactRouter() {
    return (
        <div className='Content__Wrapper'>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/invoicing" element={<Invoicing />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/accounting" element={<Accounting />} />
                <Route path="/advisors" element={<Advisor />} />
                <Route path="/recurring-billing" element={<Recurring />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/security" element={<Security />} />
                <Route path="/protection" element={<Protection />} />
                <Route path="/payyitme" element={<Payyitme />} />
                <Route path="/bluecard" element={<Bluecard />} />
            </Routes>
        </div>
    );
}

export default ReactRouter;