import React from 'react'
import Style from './Service.module.css'
import Service1 from './Images/Service1.png';
import Service2 from './Images/Service2.png';
import Service3 from './Images/Service3.png';

const Service = () => {
    return (
        <div className={Style.Service__Section}>
            <div className='container'>
                <div className='Section__Title'>
                    <h3 className='Title mb-0'>The Payyit Blue Visa Debit offers options with convenience</h3>
                </div>
                <div className='row g-3 g-lg-4 g-xxl-5 row-cols-1 row-cols-lg-3'>
                    <div className='col'>
                        <div className={Style.Service__Item}>
                            <div className={Style.Icon}><img src={Service1} alt='' /></div>
                            <h5 className={Style.Title}>Tap, insert, or swipe</h5>
                            <div className={Style.Desc}>Check out securely at stores, including with just a tap of your card.</div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Service__Item}>
                            <div className={Style.Icon}><img src={Service2} alt='' /></div>
                            <h5 className={Style.Title}>The safe way to shop</h5>
                            <div className={Style.Desc}>Shop with confidence knowing that your Payyit transactions are secure.</div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Service__Item}>
                            <div className={Style.Icon}><img src={Service3} alt='' /></div>
                            <h5 className={Style.Title}>No monthly fee</h5>
                            <div className={Style.Desc}>No monthly fee, minimum balance, or credit check.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
