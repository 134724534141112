import React from 'react'
import Style from './Promo.module.css';
import { Link } from 'react-router-dom'; // Import the Link component

const Promo = () => {
    return (
        <div className={Style.Promo__Section} >
            <div className='container medium'>
                <div className={Style.Promo__Box}>
                    <div className='row align-items-center g-3 gy-4 g-lg-4'>
                        <div className='col-lg-9'>
                        <h2 className={Style.Promo__Title}>Accounting made easy.</h2>
                        </div>
                        <div className='col-lg-3 text-lg-end'><Link to="https://dashboard.payyit.com/signup" className="Lilac__Button">Start Now!</Link></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo
