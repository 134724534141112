import React from 'react'

import Header from './Section/Header';
import Product from './Section/Product';
import Promo from './Section/Promo';
import Testimonial from './Section/Testimonial';
import CallToAction from './Section/CallToAction';
import Faq from './Section/Faq';
import Pricing from './Section/Pricing';

const Advisor = () => {
  return (
    <div className='Advisor__Page__Content'>
      <Header />
      <Product />
      <Promo />
      <Testimonial />
      <Pricing />
      <Faq />
      <CallToAction />
    </div>
  )
}

export default Advisor
