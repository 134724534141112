import React, { useState, useEffect } from 'react';
import styles from './Service.module.css'; // Import CSS modules
import { Link } from 'react-router-dom';


import Invoicing from './Images/Invoicing.png';
import Accounting from './Images/Accounting.png';
import Advisors from './Images/Advisors.png';
import Payments from './Images/Payments.png';


const Service = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [activeSection, setActiveSection] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
        const handleScroll = () => {
            const sections = document.querySelectorAll('.tab__row');
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.clientHeight;
            const maxScroll = documentHeight - windowHeight;
            const percentage = (scrollPosition / maxScroll) * 100;
            setScrollPercentage(percentage);
            sections.forEach(section => {
                const sectionTop = section.offsetTop + windowHeight;
                const sectionBottom = sectionTop + section.offsetHeight;
                if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
                    setActiveSection(section.id);
                }
            });
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when link is clicked
    };

    const calculateBackgroundColor = () => {
        const hue = (scrollPercentage * 3) % 360; // Ensure hue stays within 0 to 360
        return `hsl(${hue}, 35%, 91%)`;
    };

    return (
        <div className={styles.Service__Section} style={{ backgroundColor: calculateBackgroundColor() }}>
            <div className='container medium'>
                <div className='row justify-content-center'>
                    <div className='col-xl-10'>
                        <div className='Section__Title m-0'>
                            <h2 className='Title m-0'>Our tools work together, so you work less.</h2>
                        </div>
                    </div>
                </div>
                <div className={styles.Section__Service} style={{ backgroundColor: calculateBackgroundColor() }}>
                    <a className={activeSection === 'Invoicing__Section' ? styles.active : ''} href='#Invoicing__Section'>Invoicing</a>
                    <a className={activeSection === 'Payments__Section' ? styles.active : ''} href='#Payments__Section'>Payments</a>
                    <a className={activeSection === 'Accounting__Section' ? styles.active : ''} href='#Accounting__Section'>Accounting</a>
                    <a className={activeSection === 'Advisors__Section' ? styles.active : ''} href='#Advisors__Section'>Advisors</a>
                </div>
                <div className={styles.Section__Service__Content}>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' id='Invoicing__Section'>
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Invoicing} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Invoicing</h3>
                            <h5 className='m-0'>Stop chasing clients for money</h5>
                            <div className='mb-4'>
                                <p>Reduce unpaid invoices, keep tabs on your income, and get paid faster.</p>
                            </div>
                            <Link onClick={scrollToTop} to={'/invoicing'}>Learn more about our invoicing feature <i className='fa-regular fa-long-arrow-right'></i></Link>
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' id='Payments__Section'>
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Payments} alt='Invoicing' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Payments</h3>
                            <h5 className='m-0'>Get paid faster</h5>
                            <div className='mb-4'>
                                <p>Convenience is everything. Give your customers the option of paying with one click using a credit card, bank transfer, or Apple Pay.</p>
                            </div>
                            <Link onClick={scrollToTop} to={'/payments'}>Learn more about online payments <i className='fa-regular fa-long-arrow-right'></i></Link>
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' id='Accounting__Section'>
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Accounting} alt='accounting' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Accounting</h3>
                            <h5 className='m-0'>Track your income & expenses, painlessly</h5>
                            <div className='mb-4'>
                                <p>Monitor your cash flow, stay organized, and stop sweating tax season. Say #sorrynotsorry to your spreadsheets and shoeboxes.</p>
                            </div>
                            <Link onClick={scrollToTop} to={'/accounting'}>Learn more about our accounting feature <i className='fa-regular fa-long-arrow-right'></i></Link>
                        </div>
                    </div>
                    <div className='row gx-3 gy-4 g-md-5 row-cols-1 row-cols-md-2 tab__row' id='Advisors__Section'>
                        <div className='col'>
                            <figure className='m-0'>
                                <img src={Advisors} alt='Advisors' />
                            </figure>
                        </div>
                        <div className='col'>
                            <h3>Advisors</h3>
                            <h5 className='m-0'>Get by with a little 1:1 help</h5>
                            <div className='mb-4'>
                                <p>Our (non-judgmental) team of bookkeeping, accounting, and payroll experts is standing by to coach you—or do the work for you.</p>
                            </div>
                            <Link onClick={scrollToTop} to={'/advisors'}>Learn more about Payyit Advisors <i className='fa-regular fa-long-arrow-right'></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
