import React from 'react'
import Style from './Service.module.css'

import Clock_Icon from './Images/clock.svg';
import Bookkeeping_Icon from './Images/bookkeeping.svg';
import Recurring_Icon from './Images/recurring.svg';

const Service = () => {
    return (
        <div className={Style.Service__Section} >
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-10'>
                        <div className='Section__Title'>
                            <h3 className='Title text-start text-xxl-center'>Stop chasing clients around</h3>
                            <ul className='Inline__Check__List Description'>
                                <li>Send professional invoices</li>
                                <li>Get paid fast via credit card</li>
                                <li>Let Payyit send overdue reminders with Pro</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row g-3 g-xl-4 row-cols-1 row-cols-xl-3'>
                    <div className='col'>
                        <div className={Style.Icon__Box}>
                            <div className={Style.Icon}>
                                <img src={Clock_Icon} alt='Create invoices quickly' />
                            </div>
                            <h3 className={Style.Title}>Create invoices quickly</h3>
                            <div className={Style.Description}>
                                <p>Every invoice paid means more revenue coming into your small business. Create and send professional invoices to your customers in seconds.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Icon__Box}>
                            <div className={Style.Icon}>
                                <img src={Recurring_Icon} alt='Create invoices quickly' />
                            </div>
                            <h3 className={Style.Title}>Recurring billing for repeat customers</h3>
                            <div className={Style.Description}>
                                <p>Get paid on time and stop chasing clients. With Payyit's Pro Plan, you can set up recurring invoices and automatic credit card payments for your repeat customers. Switch between automatic and manual billing whenever you want.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={Style.Icon__Box}>
                            <div className={Style.Icon}>
                                <img src={Bookkeeping_Icon} alt='Create invoices quickly' />
                            </div>
                            <h3 className={Style.Title}>Your bookkeeping is done</h3>
                            <div className={Style.Description}>
                                <p>All your invoicing and payment information automatically syncs with Payyit's accounting feature.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
