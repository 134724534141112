import React from 'react'
import styles from './Header.module.css'; // Import CSS modules
import Header__Image from './Images/advisor__image.png';
import { Link } from 'react-router-dom';
const Header = () => {
    return (
        <div className={styles.Header__Section}>
            <div className='container'>
                <div className='row align-items-center g-3'>
                    <div className='col-lg-6 text-center text-lg-start'>
                        <h1 className={styles.Main__Title}>Personalized help with Payyit Advisors</h1>
                        <div className={styles.Description}>
                            <p>Get VIP treatment from our in-house accounting and payroll coaches and bookkeepers.</p>
                        </div>
                        <Link to={'https://calendly.com/payyit'} target='_blank' className='Primary__Button'>Book a call today</Link>
                    </div>
                    <div className='col-lg-6'>
                        <figure className={styles.Header__Image}>
                            <img src={Header__Image} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
