import React, { useState } from 'react';
import styles from './Faq.module.css';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 0

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      "question": "Is Payyit's software something I need to install?",
      "answer": "Payyit's tools are web-based, so you just need an Internet connection and browser—no installation is necessary for our online software!\n\nIf you're looking for an on-the-go solution, you can install our mobile app to help you manage invoices and payments. Download our mobile app on iOS and Android today!"
    },
    {
      "question": "Is my data secure when I use Payyit?",
      "answer": "Completely. Our servers are protected physically and electronically. Any connections between you, your bank accounts, and Payyit are protected by 256-bit SSL encryption. Payyit is a PCI-DSS Level 1 Service Provider."
    },
    {
      "question": "Do you provide customer support?",
      "answer": "We provide different levels of support, depending on the plan and/or the add-on features you choose. Learn more about how Payyit support works here."
    },
    {
      "question": "How can I create an invoice?",
      "answer": "Sign up for Payyit and send your first invoice right away—it only takes a few minutes! With the Pro Plan you can also set up recurring payments, auto-reminders, and deposit requests to make sure you always get paid on time.\n\nHow can I send my customers an invoice?\n\nWith Payyit’s web-based invoicing software, you can create and send invoices for your business in just a few clicks from your computer. All you need is an Internet connection and a browser!\n\nIf you’re on-the-go, you can also send invoices from your phone or other mobile device using the Payyit app."
    },
    {
      "question": "Can I create and send invoices on the go?",
      "answer": "Most definitely! The Payyit app lets you easily generate and send invoices to your clients wherever and whenever you need to."
    },
    {
      "question": "Does Payyit offer recurring invoicing?",
      "answer": "We sure do! With a Payyit Pro subscription, you'll have recurring billing and other automation features. Schedule everything, from invoice creation and invoice sending, to payment collection and overdue payment reminders."
    },
    {
      "question": "What payment methods can my customers use?",
      "answer": "Your customers can pay the invoices you send them instantly by credit card, secure bank payment (ACH/EFT), or Apple Pay. And by enabling Payyit’s payments feature, you can accept credit cards and bank payments, and get paid out in as fast as 1-2 business days."
    },
    {
      "question": "How do I calculate taxes on my invoices?",
      "answer": "A common issue when invoicing in Microsoft Word, Excel, or other DIY solutions is making calculation errors. Payyit's invoicing software for small businesses removes the worry of making these errors because calculations are done for you, including the taxes."
    },
    {
      "question": "How is a digital invoice different from a paper-based invoice?",
      "answer": "Electronic invoices are created with online invoicing software or other cloud-based services, which makes it easy to automate the invoicing process. Electronic invoices also provide small business owners with professional-looking digital invoices that their customers can pay easily online through a system like Payyit’s online payments.\n\nOn the other hand, paper-based invoicing is a manual process requiring extra time for things like invoice tracking and following up on overdue invoices, which can keep you away from the best parts of running your small business."
    },
    {
      "question": "What are the benefits of digital invoicing?",
      "answer": "Manually creating invoices in Microsoft Word or Excel can be time-consuming and difficult to manage. Digital invoicing empowers your small business by automating invoice processing and saving time by tracking key invoice data like upcoming and outstanding invoices. You can also manage late payments more efficiently through e-invoicing by setting up payment reminders to send to your customers before an invoice due date."
    },
    {
      "question": "Is invoicing software helpful for freelancers?",
      "answer": "Absolutely! Whether you're a freelance writer, a freelance editor, a freelance front-end developer, or even a freelance balloon maker (no articles on that yet, sorry), you can use invoicing software to send professional looking invoices to your clients and get paid faster."
    },
    {
      "question": "How do I know what to charge when invoicing my freelance clients?",
      "answer": "The rate that you charge for your freelancing services can vary, so it's important to get a grasp of market trends before sending your clients an invoice or quoting a price. Freelance rates can differ depending on experience level and industry. For example, the rate a freelance web developer charges may be different than that of a freelance graphic designer, because each freelancer specializes in a different area."
    }
  ];

  return (
    <div className={styles.Faq__Section}>
      <div className='container'>
        <div className='row g-3 g-y-5 g-xl-5'>
          <div className='col-lg-4'>
            <div className='Section__Title text-start'>
              <h3 className='Title'>Frequently Asked Questions</h3>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className={styles.Accordion__Items}>
              {faqData.map((item, index) => (
                <div className={styles.Accordion__Item} key={index}>
                  <h4 onClick={() => toggleAccordion(index)} className={`${styles.Accordion__Title} ${activeIndex === index ? styles.Active : ''}`} >
                    {item.question}
                  </h4>
                  {activeIndex === index && (
                    <div className={styles.Accordion__Content}>
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <ul className='Reset__List mt-5'>
          <li><small>1. Payouts are processed in 1-2 business days for credit card payments and 1-7 business days for bank payments. Deposit times may vary due to processing cutoff times, third party delays, or risk reviews.</small></li>
          <li><small>2. Rates are 1% for bank payments (minimum fee of $1). For information on credit card rates, visit Pricing for details.</small></li>
        </ul>
      </div>
    </div>
  )
}

export default Faq;
