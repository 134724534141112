import React from 'react'
import styles from './Customer.module.css';
import { Link } from 'react-router-dom';
import Customer__Consultants from './Images/Customer__Consultants.webp';
import Customer__Contractors from './Images/Customer__Contractors.webp';
import Customer__Freelancers from './Images/Customer__Freelancers.webp';
import Customer__Self__Employed from './Images/Customer__Self__Employed.webp';

const Customer = () => {
  return (
    <div className={styles.Customer__Section}>
      <div className='container medium'>
        <div className={`row g-0 row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-end ${styles.Customer__Grid}`}>
          <div className={`col BG__Orchid ${styles.Grid__Block}`} >
            <h4 className={styles.Heading__Title}>Built for small business owners like you</h4>
            <Link to={'https://dashboard.payyit.com/signup'} className={`Primary__Button mt-2 ${styles.Header__Button}`}>Sign me up</Link>
          </div>
          <div className={`col ${styles.Grid__Block}`} >
            <h5 className={styles.Block__Title}>FREELANCERS</h5>
            <figure className='m-0'>
              <img src={Customer__Freelancers} alt='' />
            </figure>
            <div className='Description'>
              <p>Create and send professional invoices in minutes.</p>
            </div>
          </div>
          <div className={`col ${styles.Grid__Block}`} >
            <h5 className={styles.Block__Title}>SELF-EMPLOYED ENTREPRENEURS</h5>
            <figure className='m-0'>
              <img src={Customer__Self__Employed} alt='' />
            </figure>
            <div className='Description'>
              <p>Pay your staff (and yourself!) with confidence.</p>
            </div>
          </div>
          <div className={`col ${styles.Grid__Block}`} >
            <h5 className={styles.Block__Title}>CONSULTANTS</h5>
            <figure className='m-0'>
              <img src={Customer__Consultants} alt='' />
            </figure>
            <div className='Description'>
              <p>Set up recurring invoices and payments for retainer clients.</p>
            </div>
          </div>
          <div className={`col ${styles.Grid__Block}`} >
            <h5 className={styles.Block__Title}>CONTRACTORS</h5>
            <figure className='m-0'>
              <img src={Customer__Contractors} alt='' />
            </figure>
            <div className='Description'>
              <p>Track your business expenses with our free accounting tools.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customer
