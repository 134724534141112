import React from 'react'
import Style from './Feature.module.css'

import Image1 from './Images/recurring-payments-featiure.webp'
import Image2 from './Images/recurring-payments-feature-2.svg'

const Feature = () => {
    return (
        <div className={Style.Feature__Section}>
            <div className='container'>
                <div className='row g-3 g-lg-4 g-xxl-5 align-items-center'>
                    <div className='col-md-6'>
                        <figure className='m-0'>
                            <img src={Image1} alt='' />
                        </figure>
                    </div>
                    <div className='col-md-6 col-xl-5 offset-xl-1'>
                        <h4 className='fw-bold'>Save time with recurring payments</h4>
                        <div className='Description'>
                            <p>Quickly set up credit card payments and switch between automatic and manual billing whenever you want. When the feature is off, we'll send the invoice for you. When it's on, we'll send your customer an email receipt.</p>
                        </div>
                    </div>
                </div>
                <div className='row g-3 g-lg-4 g-xxl-5 flex-row-reverse mt-5 align-items-center'>
                    <div className='col-md-6'>
                        <figure className='m-0 text-md-end'>
                            <img src={Image2} alt='' />
                        </figure>
                    </div>
                    <div className='col-md-6 col-xl-5 offset-xl-1 text-md-end'>
                        <h4 className='fw-bold'>Save time with recurring payments</h4>
                        <div className='Description'>
                            <p>Quickly set up credit card payments and switch between automatic and manual billing whenever you want. When the feature is off, we'll send the invoice for you. When it's on, we'll send your customer an email receipt.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
