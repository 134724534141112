import React from 'react'
import Header from './Sections/Header'
import Service from './Sections/Service'
import Feature from './Sections/Feature'
import CallToAction from './Sections/CallToAction'

const Payyitme = () => {
  return (
    <div className='Payyitme__Page__Wrapper'>
        <Header />
        <Service />
        <Feature />
        <CallToAction />
    </div>
  )
}

export default Payyitme
