import React from 'react'

import Style from './Testimonial.module.css';
import Client__1 from './Images/Client_1.jpg';


const Testimonial = () => {
    return (
        <div className={Style.Testimonial__Section}>
            <div className='container'>
                <div className={Style.Testimonial__Item}>
                    <figure className={Style.Testimonial__Image}>
                        <img src={Client__1} alt='Jasmine Fleming - Freelance Writer' />
                    </figure>
                    <div className={Style.Testimonial__Content}>
                        <div className={Style.Description}>
                            <p>The majority of my clients prefer to pay via credit card using the <strong>secure link provided on the invoice</strong>. This method proves to be a significant time-saver and facilitates faster payment processing for me!</p>
                        </div>
                        <div className={Style.Name}>Emily Smith. - Cadbury</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
