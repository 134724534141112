import React, { useState } from 'react';
import styles from './Faq.module.css';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 0

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      "question": "What if I just want to try Payyit to see if it works for my business?",
      "answer": "Sign up for the Starter Plan to take the bookkeeping and invoicing basics for a spin. Then, whenever you're ready, upgrade to the Pro Plan to unlock even more money management features. Our plans are built for small business owners at every stage!"
    },
    {
      "question": "Do I automatically qualify for online payments if I sign up for Pro?",
      "answer": "Approval to use online payments is subject to eligibility criteria, including identity verification and credit review. You'll need to answer a few questions about your business and provide us with a little more information about yourself in order to get approved to accept online payments."
    },
    {
      "question": "Is it easy to cancel for any reason after I subscribe?",
      "answer": "Yes, you can unsubscribe at any time! The cancellation of your subscription will take effect at the end of the billing period in which you cancel. Learn how to cancel your subscription in our Help Center."
    },
    {
      "question": "Can I change plans easily?",
      "answer": "You bet! To upgrade to the Pro Plan, sign into Payyit. Visit your dashboard or use your invoicing or accounting features, and click the peach “Upgrade now” button. Learn more about changing subscription periods and plan types, and how to cancel your Pro subscription in our Help Centre."
    },
    {
      "question": "Is the subscription fee for the Pro Plan per owner or per business?",
      "answer": "The subscription fee for the Pro Plan is per business, not per owner. A single Payyit account can be used to manage multiple business profiles within it, and if you have e.g. three businesses under one account and want them all on the Pro Plan, you'd sign each business up and subscribe to and pay (plus applicable taxes) three times."
    }
  ]
  ;

  return (
    <div className={styles.Faq__Section}>
      <div className='container'>
        <div className='row g-3 g-y-5 g-xl-5'>
          <div className='col-lg-4'>
            <div className='Section__Title text-start'>
              <h3 className='Title'>Frequently Asked Questions</h3>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className={styles.Accordion__Items}>
              {faqData.map((item, index) => (
                <div className={styles.Accordion__Item} key={index}>
                  <h4 onClick={() => toggleAccordion(index)} className={`${styles.Accordion__Title} ${activeIndex === index ? styles.Active : ''}`} >
                    {item.question}
                  </h4>
                  {activeIndex === index && (
                    <div className={styles.Accordion__Content}>
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq;
