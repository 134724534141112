import React from 'react'
import Style from './Feature.module.css'
import Manage_Accounts from './Images/Manage_Accounts.png';
import Send_Invoices from './Images/Send_Invoices.webp';

const Feature = () => {
    return (
        <div>
        <div className={Style.Feature__Section}>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-10'>
                        <div className='Section__Title'>
                            <h3 className='Title'>Manage accounts receivable effortlessly</h3>
                            <div className='Description'>
                                <p>Powerful invoice management features</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row g-3 gy-5 g-lg-5 align-items-center'>
                    <div className='col-lg-6'>
                        <div className='row g-3 gy-4 g-lg-5 row-cols-1'>
                            <div className='col'>
                                <h6 className='fw-semibold'>Access all customer information in one place</h6>
                                <div className='Description'>
                                    <p>You'll always know who you need to communicate with and about what, so you can leave a great impression with customers.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <h6 className='fw-semibold'>Track payments and communications</h6>
                                <div className='Description'>
                                    <p>From invoices to automated reminder emails, view your history with each customer for a clear picture of what to prioritize.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <figure className='m-0 text-lg-end'>
                            <img src={Manage_Accounts} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
            <div className={Style.Feature__Section}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xl-10'>
                            <div className='Section__Title'>
                                <h3 className='Title'>Get paid faster</h3>
                                <div className='Description'>
                                    <p>Reliable, timely payments means better cash flow.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 gy-5 g-lg-5 align-items-center flex-row-reverse'>
                        <div className='col-lg-6'>
                            <div className='row g-3 gy-4 g-lg-5 row-cols-1'>
                                <div className='col'>
                                    <h6 className='fw-semibold'>Invoice on the go</h6>
                                    <div className='Description'>
                                        <p>Send invoices whenever and wherever you want with Payyit's free app for iOS and Android.</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <h6 className='fw-semibold'>Instant notification</h6>
                                    <div className='Description'>
                                        <p>Know when an invoice is viewed, becomes due, or gets paid, so you can take the right actions to manage your cash flow. Set up invoice reminders to automatically email your customers when payment is due.</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <h6 className='fw-semibold'>Accept payments online</h6>
                                    <div className='Description'>
                                        <p>Get paid in as fast as 1-2 business days, enhance your brand, and look more professional when you enable payments. Customers can click a Pay Now button on invoices and pay instantly by credit card, secure bank payment (ACH/EFT), or Apple Pay. You can accept credit cards and bank payments for as little as 1% per transaction.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0'>
                                <img src={Send_Invoices} alt='' />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
