import React from 'react'
import Style from './Privacy.module.css'

const Privacy = () => {
    return (
        <>
            <div className='Navbar__Height'></div>
            <div className={Style.Privacy__Page__Section}>
                <div className={Style.Header__Section}>
                    <div className='container'>
                        <h1 className={Style.Page__Title}>Privacy Policy</h1>
                        <h5 className={Style.Sub__Title}>At Payyit Inc. (“Payyit”), we value your trust and respect your privacy, Payyit exists to support entrepreneurs like you by offering a fast and easy way to manage your finances, while exceeding your privacy expectations.</h5>
                    </div>
                </div>
                <div className={Style.Content__Sectioin}>
                    <div className='container'>
                        <h4>Scope</h4>
                        <p>This privacy policy applies to&nbsp;<a href="/" target="_blank" rel="noreferrer">www.payyit.com</a>&nbsp;and
                            its subdomains (the &ldquo;Site&rdquo;), and our mobile apps, all of which are owned and
                            operated by Payyit Inc. It does not apply to any third-party websites, which
                            have their own policies. Throughout this policy, when we say Payyit, we mean our
                            company, including the Site and any Payyit mobile apps, and when we say Services we
                            mean the various financial products and services, and apps we make available to you
                            through our Site and mobile apps, including our payments, payroll, accounting, invoicing
                            services, and other small business&ndash;related services and support.</p>
                        <p>By &ldquo;Personal Information&rdquo;, we mean information about an identifiable
                            individual. That&rsquo;s what this policy is about &ndash; our collection, protection,
                            use, retention, disclosure and other processing of Personal Information and your rights
                            relating to these activities. We also compile certain aggregate data about our users.
                        </p>
                        <p>What is aggregate data? &ldquo;Tim Smith buys a coffee at a coffee shop on Main
                            Street&rdquo; is the Personal Information of Tim Smith, but &ldquo;20% of Payyit users
                            buy coffee on Tuesdays&rdquo; is aggregate data.</p>
                        <p>We use Personal Information in order to provide you and your business with the
                            Services. Our Privacy Policy is based on the following 10 fair information principles:
                        </p>
                        <ol>
                            <li>Accountability</li>
                            <li>Identifying purposes</li>
                            <li>Consent</li>
                            <li>Limiting collection</li>
                            <li>Limiting use, disclosure, and retention</li>
                            <li>Accuracy</li>
                            <li>Safeguards</li>
                            <li>Openness</li>
                            <li>Individual access</li>
                            <li>Challenging compliance</li>
                        </ol>

                        <h4>Accountability and Challenging Compliance (#1 and #10)</h4>

                        <p>Payyit has named a Privacy Officer who is responsible for all things privacy at
                            Payyit. This includes our policies and procedures that are designed to keep your
                            information safe. If you have any questions about our privacy practices or this policy,
                            you can contact me at:</p>

                        <ul>
                            <li>Payyit Inc.</li>
                            <li>Privacy Officer</li>
                            <li>555 W 5th St</li>
                            <li>35th Floor</li>
                            <li>Los Angeles, CA 90013</li>
                            <li> United States of America</li>
                        </ul>
                        <p>Email:&nbsp;
                            <a href="mailto:privacy@payyit.com" target="_blank" rel="noreferrer">
                                <strong>
                                    privacy@payyit.com
                                </strong>
                            </a></p>

                        <h4>Identifying Purposes (#2)</h4>

                        <p>Payyit collects, uses, discloses, and otherwise manages Personal Information in a
                            variety of ways related to the Services we provide. We&rsquo;ve outlined these below.
                            Your use of the Services determines which information we collect and use. For example,
                            payroll information is optional, unless you&rsquo;d like to use Payroll by Payyit to
                            run payrolls, at which point we will need to collect payroll information in order to
                            provide you with this service. Similarly, when you create an invoice, your
                            customer&rsquo;s email is optional, but if you&rsquo;d like to us to deliver the invoice
                            by email, this information becomes necessary.</p>
                        <p>Payyit may collect, use, store, or disclose your Personal Information for the purposes
                            described below.</p>
                        <ul>
                            <li>In order to provide you with our Services:</li>
                        </ul>
                        <ul>
                            <ul>
                                <li>We collect information directly from you, but may also collect information from
                                    third parties when you connect your Payyit account to them. These integrations
                                    may pull data into or share data out of Payyit. In some cases, we use a service
                                    provider to connect you to a third party service (e.g. when you
                                    connect to your bank account to pull in transactions).</li>
                            </ul>
                        </ul>
                        <ul>
                            <ul>
                                <li>We may also collect your name and email address from third parties when you sign
                                    up and login to our Site using single sign-on (SSO).</li>
                            </ul>
                        </ul>
                        <ul>
                            <ul>
                                <li>When you connect your Payyit account with a third-party service, their terms
                                    and policies apply.</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>To promote or offer you products or services, and to determine your eligibility for
                                new services we may offer from time to time.</li>
                            <li>To provide you with educational materials and guides relevant to the Services you
                                use.</li>
                            <li>To contact you for the purposes of Service updates and system and account
                                notifications.</li>
                            <li>To provide you with support in connection with the Services.</li>
                            <li>To comply with any laws, regulation, court orders, warrants, inquiries, subpoenas or
                                other legal processes or investigations, and to protect ourselves, other
                                individuals, or property from harm.</li>
                            <li>In connection with a prospective or completed merger or sale (including insolvency
                                or bankruptcy proceedings) involving all or part of Payyit or as part of a
                                corporate reorganization or stock sale or other change in corporate control.</li>
                        </ul>

                        <h4 class="font-weight-light">We will never sell your Personal Information to other
                            companies.</h4>


                        <h4>Consent (#3)</h4>

                        <p>Payyit takes a consent-based approach to the collection, use and disclosure of
                            Personal Information.</p>

                        <h4>Submitting the Personal Information of others</h4>

                        <p>When you submit the Personal Information of your customers or employees to us, you are
                            responsible for informing such customers and employees about Payyit, and for obtaining
                            any necessary consent or authority from them.</p>
                        <p>You may also choose to refer someone to our Site. We will send them a single invitation
                            email to the address you provide. Please ensure that you only submit email addresses of
                            individuals or businesses with whom you have a relationship and who would want to
                            receive the message.</p>

                        <h4>Closing your Payyit account</h4>

                        <p>At any time and without penalty, Payyit users can withdraw their consent to the
                            continued use or disclosure of their Personal Information by closing their Payyit
                            account. Please ensure you complete the account closure process which includes a
                            confirmation email. Otherwise, your account may not be closed.</p>

                        <h4>Email and communications consent</h4>

                        <p>At any time, you can opt-out of commercial email communications from us by clicking on
                            the unsubscribe link in such emails, or via the Email Preferences settings in your
                            account. Certain non-commercial communications may still be sent to you that are
                            required to provide you with our Services. For example, system notifications, major
                            product changes, changes to our Terms of Use, or other news that we believe will
                            materially affect how you interact with Payyit.</p>
                        <p>The only way to completely stop all emails from Payyit is to close your account as
                            outlined above.</p>

                        <h4>Limiting Collection (#4)</h4>

                        <p>Payyit only collects the Personal Information necessary to provide the Services to
                            you. The Services you use will determine which information Payyit collects. We&rsquo;ll
                            also provide you the option of sharing additional information to enhance your Payyit
                            experience.</p>
                        <p>Payyit may also use third party services to supplement or enrich our understanding of
                            our customers. This includes cross-referencing information like a name, business name,
                            email address or IP address in third-party databases, and using the information there to
                            improve our understanding of you and your business.</p>
                        <p>Payyit is not intended for children and we do not knowingly or intentionally collect
                            information about individuals under the age of thirteen (13).</p>
                        <p>Some of the Services allow you to provide access to your Payyit account to other
                            authorized users, which we call &ldquo;guest collaborators&rdquo;. Guest collaborators
                            have access to information in your Payyit account, including Personal Information, and
                            may perform various tasks on your behalf. You take full responsibility for any
                            collection, use or disclosure of your Personal Information by your collaborator(s).
                        </p>
                        <p>To provide access to your account to a guest collaborator, you must provide the
                            collaborator&rsquo;s name and email address. We will send them a single email inviting
                            them to the Site. Payyit only uses this information to invite your collaborator. Please
                            ensure that you only submit email addresses of individuals or businesses that you have a
                            relationship with and who would want to receive the message. The guest collaborator may
                            contact us at&nbsp;<strong><a href="mailto:privacy@payyit.com"
                                target="_blank" rel="noreferrer">privacy@payyit.com</a></strong> to request that we remove their
                            name and email address from our database.</p>

                        <h4>Limiting Use, Disclosure and Retention (#5)</h4>

                        <p>We will use your Personal Information as described in this policy.</p>
                        <p>We will share your Personal Information with third parties only as described in this
                            policy.</p>
                        <p>We will retain your information for the period necessary to fulfill the purposes
                            outlined in this policy, unless a longer retention period is required by law or
                            regulation. To be clear, that means we&rsquo;ll retain your Personal Information while
                            you have an active account, and afterward if we need to do so to meet our legal
                            obligations. If you choose to close your Payyit account, we will destroy your Personal
                            Information within 90 days unless we are otherwise obliged to keep it longer.</p>

                        <h4>Accuracy (#6)</h4>

                        <p>Payyit relies on you to provide us with information that is accurate and complete. We
                            provide you the mechanisms and rely on you to keep your information up to date. You can
                            request updates or corrections of any inaccuracies in your Personal Information at any
                            time by contacting us at the contact information listed in the policy. We will respond
                            to your request within a reasonable timeframe.</p>

                        <h4>Safeguards (#7)</h4>

                        <p>Payyit uses a combination of reasonable and appropriate safeguards designed to protect
                            your information. These safeguards are administrative controls (policies,
                            procedures, and training), technical controls (encryption, firewalls, and
                            secure coding frameworks), and physical controls (secured hosting environments).</p>


                        <p>For additional details, please see our <a href="https://payyit.com/security"
                            target="_blank" rel="noreferrer">Security</a>&nbsp;page.</p>


                        <p>We ensure that any third party acting on our behalf maintains reasonable and
                            appropriate safeguards in respect of your Personal Information. Additional information
                            about our third parties&rsquo; privacy practices is available upon request.</p>
                        <p>If you have questions about security on our Site, you can contact us at&nbsp;<strong><a href="mailto:security@payyit.com"
                            target="_blank" rel="noreferrer">security@payyit.com</a></strong><strong>.</strong></p>
                        <p>You are also responsible for helping to protect the security of your Personal
                            Information. For instance, never give out your email account information or your
                            password for the Services to third parties. Our team will never request your password or
                            PIN, and we ask that you never post account or credit card numbers to our support
                            channels.</p>

                        <h4>Openness (#8)</h4>

                        <p>This policy outlines our privacy practices. If you have questions about it, please
                            contact our&nbsp;<strong>Privacy Officer</strong>. This policy is available publicly
                            at&nbsp;<strong><a href="/privacy">https://payyit.com/privacy</a></strong>.
                        </p>

                        <h4>Individual Access (#9)</h4>

                        <p>You may access, update and correct inaccuracies in the Personal Information in our
                            custody or control at any time, subject to limited exceptions prescribed by law. You can
                            download or export data you input into the Site at any time, or to correct inaccuracies,
                            simply log in to your account and make the necessary changes.</p>

                        <h4>ADDITIONAL DETAILS</h4>

                        <h4>Public Content and Social Media</h4>
                        <p>Payyit has public forums and blogs. Any information submitted there may be read and
                            collected by anyone.</p>
                        <p>You may request removal of Personal Information from forum or blog posts and comments
                            by contacting us at&nbsp;<strong><a href="mailto:privacy@payyit.com"
                                target="_blank" rel="noreferrer">privacy@payyit.com</a></strong><strong>.</strong></p>
                        <p>If you provide us with a testimonial, with your consent we may post it on our Site or
                            in other materials and media, along with your name. If you want your testimonial
                            removed, please contact us at&nbsp;<strong><a href="mailto:privacy@payyit.com"
                                target="_blank" rel="noreferrer">privacy@payyit.com</a></strong><strong>.&nbsp;</strong></p>
                        <p>You may engage with us through social media sites. When you engage with us on these
                            sites, we may have access to certain information about your account (<em>e.g.</em>,
                            name, username). These sites may collect your IP address, how you&rsquo;re engaging with
                            us, and may use cookies to enable the site to function properly. We may use this
                            information to personalize your experience and to provide you with other products or
                            services you may request.</p>
                        <h4>Service Providers</h4>

                        <p>We may transfer (or otherwise make available) your personal information to third
                            parties who provide services on our behalf. For example, we may use service providers to
                            host our website and to process payments. Your Personal Information may be maintained
                            and processed by these third parties in other jurisdictions, like the U.S.. When your
                            information is in another jurisdiction, it will be subject to their laws. We only share
                            the information these service providers need to do their job and we don&rsquo;t
                            authorize them for any other use or disclosure of personal information.</p>
                        <p>We may use service providers to verify bank account information you provide to us in
                            providing our Services to you. If you are in the U.S., please note that:</p>
                        <h4 class="font-weight-light">Payyit uses Plaid Inc. (&ldquo;Plaid&rdquo;) to gather your
                            data from financial
                            institutions. By using our service, you grant Payyit and Plaid the right, power,
                            and authority to act on your behalf to access and transmit your personal and
                            financial information from the relevant financial institution. You agree to your
                            personal and financial information being transferred, stored, and processed by Plaid
                            in accordance with their privacy policy.</h4>

                        <p>We may also use services provided by third-party platforms (such as social networking
                            sites) to serve targeted ads on such platforms to you or others, and we may provide a
                            hashed version of your email address or other information to the platform provider for
                            such purposes. To opt-out of the sharing of your information with such platforms, please
                            send an email to&nbsp;<strong><a href="mailto:privacy@payyit.com"
                                target="_blank" rel="noreferrer">privacy@payyit.com</a></strong><strong>.&nbsp;</strong></p>

                        <h4>Visiting the Site and using the Mobile Apps</h4>

                        <p>In general, you can visit the Site without telling us who you are or submitting any
                            Personal Information. However, we and/or our service providers (such as Google
                            Analytics) collect IP (Internet protocol) addresses from all visitors to the Site and
                            other related information such as page requests, browser type, operating system and
                            average time spent on our Site. When you use any of our mobile apps, we also collect
                            device type, operating system, unique device identifier, and date and time stamp. This
                            information is used to help us understand the activity on, and to monitor and improve,
                            our Site and mobile apps.</p>

                        <h4>Cookies, Tags, and Web Beacons</h4>

                        <p>Technologies such as cookies, web beacons, tags and scripts are used by Payyit, our
                            advertising and analytics service providers (such as Google analytics), and affiliates
                            to analyze usage trends, administer the Site, and to gather demographic information
                            about our user base as a whole.</p>
                        <p>A cookie is small piece of data that our Site can send to your browser, which may then
                            be stored on your device so we can recognize you when you return. We use cookies for
                            session tracking to make it possible to navigate the secure environment inside our Site.
                            These cookies (1) may let us know who you are, (2) are necessary to access your account,
                            and (3) will give us information that we can use to personalize our Site according to
                            your preferences. You can control the use of cookies in your browser. If you disable
                            cookies, you will not be able to access your account or take advantage of all of the
                            features of the Site and mobile apps.</p>

                        <h4>Notification of Privacy Policy Changes</h4>

                        <p>We may update this privacy policy to reflect changes to our information practices. If
                            we make any material changes we will notify you by email (sent to the e-mail address
                            specified in your account) or by means of a notice on our website prior to the change
                            becoming effective. We encourage you to periodically review our Privacy Policy for the
                            latest information on our privacy practices.</p>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Privacy
