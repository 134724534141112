import React, { useState } from 'react';
import styles from './Faq.module.css';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 0

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      "question": "Do I need to install Payyit's software?",
      "answer": "No installation needed! Payyit operates online, accessible with a browser and internet. For mobile management, download our app on iOS or Android."
    },
    {
      "question": "Can I test Payyit for my business?",
      "answer": "Absolutely! Start with the Starter Plan for basic bookkeeping and invoicing. Upgrade to Pro Plan for more features when you're ready."
    },
    {
      "question": "Is it simple to change plans?",
      "answer": "Definitely! Just log in, use the dashboard or features, and hit 'Upgrade now'. Check our Help Center for more on plan changes."
    },
    {
      "question": "How secure is my data with Payyit?",
      "answer": "Your data is safe with us. We secure our servers and encrypt connections with 256-bit SSL. Payyit is also a PCI-DSS Level 1 Service Provider."
    },
    {
      "question": "What customer support does Payyit offer?",
      "answer": "Support varies by plan and add-ons. Visit our support section to learn more."
    },
    {
      "question": "Is canceling my subscription easy?",
      "answer": "Yes, cancel anytime. Subscription ends after the current billing period. Our Help Center guides you through cancellation."
    },
    {
      "question": "Can I import data from other apps like Quickbooks?",
      "answer": "Switching to Payyit is straightforward. Follow our guide to import your bookkeeping data."
    },
    {
      "question": "Can I set different user privileges?",
      "answer": "Payyit allows you to add users like accountants as collaborators with ease. Check our guide for details."
    },
    {
      "question": "Does Payyit work with my bank?",
      "answer": "With Pro Plan, connect your bank to Payyit for automatic transaction imports, simplifying bookkeeping."
    },
    {
      "question": "Why use accounting software for my business?",
      "answer": "Accounting software eases book balancing, separates expenses, and prepares for tax season. It organizes financials, saves time, and aids in analyzing your business's financial health."
    },
    {
      "question": "How does accounting software function?",
      "answer": "It tracks money flow, categorizes transactions, and generates financial statements, replacing manual methods. Connect it to your bank for automated tasks."
    },
    {
      "question": "What features are essential in accounting tools?",
      "answer": "Look for features that track income/expenses, manage payables/receivables, organize receipts, automate tasks, run reports, generate financial statements, calculate cash flow, track sales/tax, and allow multiple users."
    },
    {
      "question": "Why switch to Payyit from another accounting solution?",
      "answer": "Payyit offers essential features for accurate bookkeeping and smooth business operation. Trusted by millions, it includes invoicing, payroll, and payment processing for comprehensive money management."
    }
  ];

  return (
    <div className={styles.Faq__Section}>
      <div className='container'>
        <div className='row g-3 g-y-5 g-xl-5'>
          <div className='col-lg-4'>
            <div className='Section__Title text-start'>
              <h3 className='Title'>Frequently Asked Questions</h3>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className={styles.Accordion__Items}>
              {faqData.map((item, index) => (
                <div className={styles.Accordion__Item} key={index}>
                  <h4 onClick={() => toggleAccordion(index)} className={`${styles.Accordion__Title} ${activeIndex === index ? styles.Active : ''}`} >
                    {item.question}
                  </h4>
                  {activeIndex === index && (
                    <div className={styles.Accordion__Content}>
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq;
