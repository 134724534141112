import React from 'react'
import Style from './Feature.module.css'
import overdue_Invoices from './Images/Invoices-overdue.webp';

const Feature2 = () => {
    return (
        <div className={Style.Feature__Section}>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-10'>
                        <div className='Section__Title'>
                            <h3 className='Title'>Organized and stress-free small business accounting</h3>
                            <div className='Description'>
                                <p>Payyit makes accounting as simple as possible.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row g-3 gy-5 g-lg-5 align-items-center flex-row-reverse'>
                    <div className='col-lg-6'>
                        <div className='row g-3 gy-4 g-lg-5 row-cols-1'>
                            <div className='col'>
                                <h5 className='fw-bold'>Make tax time a breeze</h5>
                                <div className='Description'>
                                    <p>When everything is neatly where it belongs, tax time is simple. Payyit's smart dashboard organizes your income, expenses, payments, and invoices.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <h5 className='fw-bold'>We're serious about security</h5>
                                <div className='Description'>
                                    <p>Bank data connections are read-only and use 256-bit encryption. Servers are housed under physical and electronic protection. Payyit is PCI Level-1 certified for handling credit card and bank account information.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <h5 className='fw-bold'>Accountant-friendly software</h5>
                                <div className='Description'>
                                    <p>Payyit uses real, double-entry accounting software. Don't know what that is? No sweat. Accountants do, and they'll thank you for it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <figure className='m-0'>
                            <img src={overdue_Invoices} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature2
