import React from 'react'
import Style from './Service.module.css'
import Dollar__Icon from './Images/payments__dollar__icon.webp'
import Dollar__Hand from './Images/dollar__hand.webp'
import Money__Down from './Images/Money__Down.webp'

const Service = () => {
  return (
    <div className={Style.Service__Section}>
      <div className='container'>
        <div className='Section__Title'>
          <h3 className='Title text-start text-center m-0'>Streamlining Payments with Payyit.Me Lynk</h3>
        </div>
        <div className='row g-3 gy-4 g-lg-4 row-cols-1 row-cols-lg-3'>
          <div className='col'>
            <div className={Style.Service__Item}>
              <div className={Style.Icon}>
                <img src={Dollar__Icon} alt='' />
              </div>
              <h5 className={Style.Title}>Simplify with Payyit.Me Lynk</h5>
              <div className={Style.Description}>
                <p>Share your Lynk easily with friends and family for splitting bills or chipping in for gifts. Perfect for reimbursing friends hassle-free.</p>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className={Style.Service__Item}>
              <div className={Style.Icon}>
                <img src={Dollar__Hand} alt='' />
              </div>
              <h5 className={Style.Title}>Streamline Your Freelance Work</h5>
              <div className={Style.Description}>
                <p>Manage orders and bookings effortlessly with a simple link for your customers. Plus, enjoy added security with Chargeback Insurance for your business account.</p>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className={Style.Service__Item}>
              <div className={Style.Icon}>
                <img src={Money__Down} alt='' />
              </div>
              <h5 className={Style.Title}>Secure Solutions for Business</h5>
              <div className={Style.Description}>
                <p>Protect your data and your customers' information with Payyit.Me Lynk. Stay safe and secure while managing transactions for your business.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service
