import React from 'react'
import Style from './Feature.module.css'
import Payments_proffesional from './Images/Payments_proffesional.webp';
import Send_Invoices from './Images/Send__Invoice.webp';
import Track__Payments from './Images/Track__Payments.webp';

const Feature = () => {
    return (
        <div>
            <div className={Style.Feature__Section}>
                <div className='container medium'>
                    <div className='row justify-content-center'>
                        <div className='col-xl-10'>
                            <div className='Section__Title'>
                                <h3 className='Title text-start text-md-center'>Payment solutions for your business</h3>
                                <ul className='Inline__Check__List Description'>
                                    <li>Get paid in 1-2 business days</li>
                                    <li>Low fees</li>
                                    <li>Customer convenience</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 g-lg-5 align-items-center'>
                        <div className='col-lg-6'>
                            <h5 className='fw-bold'>Access all customer information in one place</h5>
                            <div className='Description'>
                                <p>Get paid in as fast as 1-2 business days, enhance your brand, and look more professional when you enable Payments.</p>
                                <p>Accept credit cards, bank payments, and Apple Pay for as little as 1% per transaction.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0 text-lg-end'>
                                <img src={Payments_proffesional} alt='' />
                            </figure>
                        </div>
                    </div>
                    <div className='row g-3 g-lg-5 align-items-center mt-0 flex-row-reverse'>
                        <div className='col-lg-6'>
                            <h5 className='fw-bold'>Give your customers what they want</h5>
                            <div className='Description'>
                                <p>Your customers want to pay online. Simply email your customers an invoice and they can use the secure “Pay Now” button to send over your payment.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0 text-lg-end'>
                                <img src={Send_Invoices} alt='' />
                            </figure>
                        </div>
                    </div>
                    <div className='row g-3 g-lg-5 align-items-center mt-0'>
                        <div className='col-lg-6'>
                            <h5 className='fw-bold'>Never lose track of payments</h5>
                            <div className='Description'>
                                <p>With Payyit, your invoices and payments automatically flow into your accounting records. You'll never lose track of payments again, and you'll be all set at tax time.</p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <figure className='m-0 text-lg-end'>
                                <img src={Track__Payments} alt='' />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
