import React from 'react'

import Style from './Testimonial.module.css';
import Client__1 from './Images/Client_1.jpg';


const Testimonial = () => {
    return (
        <div className={Style.Testimonial__Section}>
            <div className='container'>
                <div className={Style.Testimonial__Item}>
                    <figure className={Style.Testimonial__Image}>
                        <img src={Client__1} alt='Alex Carter - Alex Carter' />
                    </figure>
                    <div className={Style.Testimonial__Content}>
                        <div className={Style.Description}>
                            <p>Signing up with Payyit was a breeze. It's been a great <strong>choice for easy, spot-on accounting, helping me keep my</strong> finances in check without any hassle.</p>
                        </div>
                        <div className={Style.Name}>Alex Carter - Alex Carter</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
