import React from 'react'
import styles from './Header.module.css'; // Import CSS modules
import Header__Image from './Images/Header_Image.webp';
import { Link } from 'react-router-dom';
const Header = () => {
    return (
        <div className={styles.Header__Section}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 text-center text-lg-start'>
                        <h1 className={styles.Main__Title}>Help Others Know It's You They're Paying.</h1>
                        <div className={styles.Description}>
                            <p>Create your link, share it, and start receiving money. Split a bill with friends or accept payments from customers. Anyone can use it.</p>
                        </div>
                        <Link to={'https://dashboard.payyit.com/signup'} target='_blank' className='Lilac__Button'>Create Your Payyit.Me Lynk</Link>
                    </div>
                    <div className='col-lg-6'>
                        <figure className={styles.Header__Image}>
                            <img src={Header__Image} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
