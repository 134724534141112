import React, { useState } from 'react';
import styles from './Faq.module.css';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 0

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      "question": "What is a Payyit Advisor?",
      "answer": "Payyit Advisors are trained in-house professionals in bookkeeping, accounting, and tax. Experienced and passionate about the unique challenges small business owners face, Payyit Advisors can give you help and advice when you need it—during the year, when you’re closing year-end, or at tax time."
    },
    {
      "question": "What is the difference between Bookkeeping and Accounting Coaching?",
      "answer": "With Bookkeeping, you outsource your bookkeeping to us and let the pros handle it. With Accounting Coaching, we ensure you are set up correctly and coach you on how to do it yourself."
    },
    {
      "question": "What are the monthly fees for Bookkeeping with an Advisor?",
      "answer": "The fee is calculated for a calendar month of transactions, and depends on the size of your business. Schedule a call to receive a customized quote."
    },
    {
      "question": "Who is Accounting Coaching for?",
      "answer": "It’s for freelancers, consultants, contractors, and self-employed entrepreneurs who want to do their own bookkeeping with confidence. Whether you’re new to accounting software (or Payyit), or have years of experience and want to utilize our software to the fullest extent, we will coach you to succeed."
    },
    {
      "question": "Who provides the coaching services?",
      "answer": "Payyit employees who are all trained in-house."
    }
  ];

  return (
    <div className={styles.Faq__Section}>
      <div className='container'>
        <div className='row g-3 g-y-5 g-xl-5'>
          <div className='col-lg-4'>
            <div className='Section__Title text-start'>
              <h3 className='Title'>Frequently Asked Questions</h3>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className={styles.Accordion__Items}>
              {faqData.map((item, index) => (
                <div className={styles.Accordion__Item} key={index}>
                  <h4 onClick={() => toggleAccordion(index)} className={`${styles.Accordion__Title} ${activeIndex === index ? styles.Active : ''}`} >
                    {item.question}
                  </h4>
                  {activeIndex === index && (
                    <div className={styles.Accordion__Content}>
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq;
