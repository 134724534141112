import React from 'react';
import Style from './Protection.module.css';

const Protection = () => {
    return (
        <>
            <div className='Navbar__Height'></div>
            <div className={Style.Protection__Page__Wrapper}>
                <div className={Style.Header__Section}>
                    <div className='container'>
                        <h1 className={Style.Page__Title}>General Data Protection Regulation</h1>
                    </div>
                </div>
                <div className={Style.Page__Content}>
                    <div className='container'>
                        <h4>What is GDPR trying to accomplish?</h4>
                        <p>The goal of GDPR, simply put, is to keep data about EU citizens safe, and to that we say, Bravo!
                            The outgoing regulations from 1995 could never have predicted the revolutionary impact that the Internet would have on society,
                            so new rules that address things like data storage and personally identifiable information are a good thing.</p>

                        <h4>Payyit and GDPR</h4>
                        <p>GDPR is brand new legislation. Experts watching GDPR say there's still a lot open to interpretation, and clarification.
                            Nonetheless, Payyit is committed to meeting or exceeding customer and government expectations. In fact, Payyit's internal
                            policies have always been aligned with the objectives of GDPR.<p>
                            </p>For example, under GDPR:</p>
                        <ul>
                            <li>You have a right to understand the terms of use you are agreeing to.</li>
                            <li>You have a right to close your account and have personally identifiable information deleted.</li>
                            <li>You have a right to take your data with you.</li>
                            <li>You have a right to turn off direct marketing messages.</li>
                            <li>Companies must provide a 'reasonable' level of protection for personal data.</li>
                        </ul>
                        <p>We are working to meet GDPR requirements, and will keep you informed as we implement additional
                            functionality to support your privacy rights.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Protection
