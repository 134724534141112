import React, { useState } from 'react';
import styles from './Faq.module.css';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set initial active index to 0

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      "question": "Is Payyit's software something I need to install?",
      "answer": "Payyit's tools are web-based, so you just need an Internet connection and browser—no installation is necessary for our online software! If you're looking for an on-the-go solution, you can install our mobile app to help you manage invoices and payments. Download our mobile app on iOS and Android today!"
    },
    {
      "question": "What if I just want to try Payyit to see if it works for my business?",
      "answer": "Sign up for the Starter Plan to take the bookkeeping and invoicing basics for a spin. Then, whenever you’re ready, upgrade to the Pro Plan to unlock even more money management features. Our plans are built for small business owners at every stage!"
    },
    {
      "question": "Can I change plans easily?",
      "answer": "You bet! To upgrade to the Pro Plan, sign into Payyit. Visit your dashboard or use your invoicing or accounting features, and click the “Upgrade now” button. Learn more about changing subscription periods and plan types in our Help Center."
    },
    {
      "question": "Is my data secure when I use Payyit?",
      "answer": "Completely. Our servers are protected physically and electronically. Any connections between you, your bank accounts, and Payyit are protected by 256-bit SSL encryption. Payyit is a PCI-DSS Level 1 Service Provider."
    },
    {
      "question": "Do you provide customer support?",
      "answer": "We provide different levels of support, depending on the plan and/or the add-on features you choose. Learn more about how Payyit support works here."
    },
    {
      "question": "Is it easy to cancel for any reason after I subscribe?",
      "answer": "Yes, you can unsubscribe at any time! The cancellation of your subscription will take effect at the end of the billing period in which you cancel. Learn how to cancel your subscription in our Help Center."
    }
  ]
    ;

  return (
    <div className={styles.Faq__Section}>
      <div className='container'>
        <div className='row g-3 g-y-5 g-xl-5'>
          <div className='col-lg-4'>
            <div className='Section__Title text-start'>
              <h3 className='Title'>Frequently Asked Questions</h3>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className={styles.Accordion__Items}>
              {faqData.map((item, index) => (
                <div className={styles.Accordion__Item} key={index}>
                  <h4 onClick={() => toggleAccordion(index)} className={`${styles.Accordion__Title} ${activeIndex === index ? styles.Active : ''}`} >
                    {item.question}
                  </h4>
                  {activeIndex === index && (
                    <div className={styles.Accordion__Content}>
                      <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq;
