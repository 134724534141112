import React from 'react';
import styles from './About.module.css';

// Images Import
import Invoice from './Images/Invoice.svg';
import Payment from './Images/Payment.svg';
import Bookkeeping from './Images/Bookkeeping.svg';
import Advisor from './Images/Advisor.svg';
const About = () => {
    return (
        <div className={styles.About__Section}>
            <div className='container medium'>
                <div className='row justify-content-center'>
                    <div className='col-xl-10'>
                        <div className='Section__Title'>
                            <h3 className='Title text-white'>One less thing to worry about</h3>
                            <div className='Description'>
                                <p>Your Payyit account gets you access to all this and more:</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row row-cols-1 row-cols-lg-2 gx-3 gy-5 g-md-5'>
                    <div className='col'>
                        <div className={styles.Left__Icon__Text}>
                            <div className={styles.Icon}>
                                <img src={Invoice} alt='Invoice' />
                            </div>
                            <div className={styles.Text}>
                                <p><strong>Smart Invoicing:</strong> Create and send invoices that reflect your brand's professionalism. Our smart system helps you manage billing cycles with automated reminders and tracking.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={styles.Left__Icon__Text}>
                            <div className={styles.Icon}>
                                <img src={Payment} alt='Payment' />
                            </div>
                            <div className={styles.Text}>
                                <p><strong>Secure Payments:</strong> Receive payments effortlessly, with a secure system that supports a variety of payment methods. Keep your cash flow steady and reliable with Payyit.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={styles.Left__Icon__Text}>
                            <div className={styles.Icon}>
                                <img src={Bookkeeping} alt='Bookkeeping' />
                            </div>
                            <div className={styles.Text}>
                                <p><strong>Automated Bookkeeping:</strong> Ditch the spreadsheet. Our bookkeeping tool automatically organizes your financials, making it easier to understand your earnings and expenditures at a glance.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className={styles.Left__Icon__Text}>
                            <div className={styles.Icon}>
                                <img src={Advisor} alt='Advisory' />
                            </div>
                            <div className={styles.Text}>
                                <p><strong>Business Advisory:</strong> Gain access to financial advice when you need it. Our network of expert advisors is here to help you navigate the complex world of business finance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About
