import React from 'react'
import styles from './Header.module.css'; // Import CSS modules
import Header__Image from './Images/Header__image.png';
import { Link } from 'react-router-dom';
const Header = () => {
    return (
        <div className={styles.Header__Section}>
            <div className='container'>
                <div className='row align-items-center g-3'>
                    <div className='col-lg-6 text-center text-lg-start'>
                        <h1 className={styles.Main__Title}>Get The Payyit Blue Visa Debit Today</h1>
                        <div className={styles.Description}>
                            <p>Use your Payyit balance to shop online and in store everywhere Visa® is accepted.</p>
                        </div>
                        <Link to={'https://dashboard.payyit.com/signup'} target='_blank' className='Primary__Button'>Get the Card</Link>
                    </div>
                    <div className='col-lg-6'>
                        <figure className={styles.Header__Image}>
                            <img src={Header__Image} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
