import React from 'react'
import Style from './PaymentFeature.module.css'

const PaymentFeature = () => {
    return (
        <div className={Style.PaymentFeature__Section}>
            <div className='container medium'>
                <h5 className='fw-bold mb-5'>See all payments features</h5>
                <div className='row row-cols-1 row-cols-md-2 g-2 g-lg-3'>
                    <div className='col'>
                        <div className='Check__Text'>Simple fee structure with low rates and no hidden fees</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Accept credit card payments over the phone, online, or in person</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>All major cards accepted: Visa, Visa Debit, Mastercard, Mastercard Debit, and American Express</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Set up recurring billing with automatic payments for your repeat customers with the Pro Plan</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Compatible with Apple Pay</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Bank-grade security for all online payments</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Get paid within 1-2 business days for credit card payments and 1-7 business days for bank payments (ACH/EFT)</div>
                    </div>
                    <div className='col'>
                        <div className='Check__Text'>Automate bookkeeping: payments connect seamlessly with our free accounting solution</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentFeature
