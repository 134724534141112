import React from 'react'
import Style from './Security.module.css'

const Security = () => {
  return (
    <>
      <div className='Navbar__Height'></div>
      <div className={Style.Security__Page__Section}>
        <div className={Style.Header__Section}>
          <div className='container'>
            <h1 className={Style.Page__Title}>Security</h1>
          </div>
        </div>
        <div className={Style.Content__Section}>
          <div className='container'>

            <h4>Data Security</h4>
            <p>Payyit is Level 1 PCI-DSS compliant. This means that every year we have a
              third-party audit to validate our practices and make sure we are doing the right things for you and your customers.</p>

            <p><strong>Secure Data Transmission</strong>&nbsp;</p>
            <p>When you load a page in your browser, or upload something to Payyit, all that information is encrypted while
              it's moving over the internet. We lock up your data with up to 256-bit TLS encryption, the strength of protection you
              get with online banking and shopping. We also support a wide variety of cyphers — another kind of code — for our
              communications, to ensure the highest level of encryption possible, based on your browser.</p>

            <p><strong>Tokenization</strong>&nbsp;</p>

            <p>Payyit doesn't store credit card numbers, ever. Credit card information is sent directly from the app or browser to
              our payments processor, and Payyit receives a secure token back. This token is a code that authorizes Payyit to complete
              the activity securely and efficiently, without storing or exposing your credit card information.</p>

            <p><strong>Secure Data Storage</strong>&nbsp;</p>
            <p>Your accounting data is stored on servers that have strict physical access protocols, meaning there are rules in place
              limiting access to only the people who need it to do their jobs. The facilities are controlled with 24/7 monitoring, and
              the technology is digitally protected.</p>

            <p><strong>Security Testing</strong>&nbsp;</p>
            <p>Payyit uses many layers of security testing.We test our systems internally, but that's not enough in our opinion.
              We also regularly bring in third - party security firms to perform vulnerability assessments and penetration tests against our
              systems.Sounds great ? Still not enough for us.Payyit has a private bug bounty program through HackerOne.This means that
              we've got security researchers from all over the globe testing our app on an ongoing basis. It takes a lot of effort to keep
              things secure, and we're happy to go the distance.</p>

            <p><strong>Transparency</strong>&nbsp;</p>
            <p>We're not asking you to just take our word for it that we keep your data secure. We want you to understand exactly how
              it's done. That's why we've written, a very clear and understandable privacy policy. If you're invoicing your customers and
              they're paying through Payyit, we want them to be comfortable too, so we've written a special <a href="https://payyit.com/customer-privacy-policy"
                target="_blank" rel="noreferrer" > Customer Privacy Policy</a>& nbsp;</p>.

            <h4>Mobile Security</h4>
            <p>Passwords are encrypted when they're collected, when they're sent to our servers, and we never store them without encrypting them
              first. In fact, all communications between our apps and our servers are encrypted using Transport Layer Security (TLS) — the replacement
              for Secure Sockets Layer (SSL) — the highest level of security protocols available. Beyond that, we don't store any sensitive information,
              such as credit card numbers, on the device ever.</p>


            <h4>Fraud Prevention</h4>
            <p>We've built an internal risk system that uses a wide variety of tools and insights to protect you and your customers from fraud. We've
              integrated several third-party security and anti-fraud service providers to create a layered approach to risk detection, for the highest
              level of protection. And our team of risk analysts monitor high risk and out-of-pattern behavior to keep our platform safe.</p>

            <p>We've got your back when it comes to chargebacks. Our team is trained to coach you in best business practices to make sure you're collecting
              the right information up front to protect your business from chargebacks. In the event that you do receive a chargeback, our experts have the
              experience necessary to build your best case.</p>

            <h4>Bank Access Security</h4>
            <p>The connection Payyit makes with your financial institutions to import transactions is read-only.</p>

            <p>For increased security, Payyit employs industry-leading online banking services to manage bank account and password data.
              These third parties are trusted by some of the world's biggest banks, including Bank of America, Citibank, and Wells Fargo.</p>
          </div>
        </div>
      </div >
    </>
  )
}

export default Security
